<template>
  <div>
    <b-row>
      <!-- 性能指标 -->
      <b-col cols="3">
        <b-card>
          <b-card-title class="text-truncate">
            性能指标
            <small>Performance Index</small>
          </b-card-title>
          <b-card-text>
            <b-row>
              <b-col class="d-flex align-items-center" cols="6">
                <b-avatar class="mr-1" rounded variant="light-danger">
                  <b-icon-house-door-fill
                    font-scale="1.5"
                  ></b-icon-house-door-fill>
                </b-avatar>
                <div style="min-width: 0">
                  <div class="text-truncate">建筑能耗综合值</div>
                  <div class="text-truncate">
                    <span class="text-danger">62.26</span>
                    <small>&nbsp;kWh/(m<sup>2</sup>&sdot;a)</small>
                  </div>
                </div>
              </b-col>

              <b-col class="d-flex align-items-center" cols="6">
                <b-avatar class="mr-1" rounded variant="light-info">
                  <b-icon-battery-charging
                    font-scale="1.5"
                  ></b-icon-battery-charging>
                </b-avatar>
                <div style="min-width: 0">
                  <div class="text-truncate">可再生能源产能</div>
                  <div class="text-truncate">
                    <span class="text-info">63.30</span>
                    <small>&nbsp;kWh/(m<sup>2</sup>&sdot;a)</small>
                  </div>
                </div>
              </b-col>

              <b-col class="d-flex align-items-center mt-1" cols="6">
                <b-avatar class="mr-1" rounded variant="light-info">
                  <b-icon-bricks font-scale="1.5"></b-icon-bricks>
                </b-avatar>
                <div style="min-width: 0">
                  <div class="text-truncate">外墙传热系数</div>
                  <div class="text-truncate">
                    <span class="text-info">0.25</span>
                    <small>&nbsp;W/m<sup>2</sup>&sdot;K</small>
                  </div>
                </div>
              </b-col>

              <b-col class="d-flex align-items-center mt-1" cols="6">
                <b-avatar class="mr-1" rounded variant="light-primary">
                  <b-icon-square-half font-scale="1.5"></b-icon-square-half>
                </b-avatar>
                <div style="min-width: 0">
                  <div class="text-truncate">外窗传热系数</div>
                  <div class="text-truncate">
                    <span class="text-primary">0.80</span>
                    <small>&nbsp;W/m<sup>2</sup>&sdot;K</small>
                  </div>
                </div>
              </b-col>

              <b-col class="d-flex align-items-center mt-1" cols="6">
                <b-avatar class="mr-1" rounded variant="light-success">
                  <b-icon-tablet-fill font-scale="1.5"></b-icon-tablet-fill>
                </b-avatar>
                <div style="min-width: 0">
                  <div class="text-truncate">空气源热泵性能系数</div>
                  <div class="text-truncate">
                    <span class="text-success">2.40</span>
                    <small>&nbsp;COP</small>
                    <span class="text-success ml-75">3.20</span>
                    <small>&nbsp;EER</small>
                  </div>
                </div>
              </b-col>

              <b-col class="d-flex align-items-center mt-1" cols="6">
                <b-avatar class="mr-1" rounded variant="light-warning">
                  <b-icon-file-break-fill
                    font-scale="1.5"
                  ></b-icon-file-break-fill>
                </b-avatar>
                <div style="min-width: 0">
                  <div class="text-truncate">地源热泵性能系数</div>
                  <div class="text-truncate">
                    <span class="text-warning">3.30</span>
                    <small>&nbsp;COP</small>
                    <span class="text-warning ml-75">3.85</span>
                    <small>&nbsp;EER</small>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>

        <b-card style="height: 334px">
          <b-card-title class="text-truncate">
            实时能效
            <small>Realtime Energy Performance</small>
          </b-card-title>
          <b-card-text>
            <b-tabs>
              <b-tab
                @click="setRealTimeEnergies(0)"
                title="今日"
                active
                class="mb-0"
              ></b-tab>
              <b-tab
                @click="setRealTimeEnergies(1)"
                title="本月"
                class="mb-0"
              ></b-tab>
              <b-tab
                @click="setRealTimeEnergies(2)"
                title="今年"
                class="mb-0"
              ></b-tab>
            </b-tabs>

            <b-row>
              <b-col cols="4">
                <b-alert show variant="danger" class="mb-0">
                  <div class="alert-body text-center py-2">
                    <b-icon-lightning-fill font-scale="1.5" class="my-50" />
                    <p class="mb-1">耗电量</p>
                    <div
                      class="text-danger font-digit text-truncate"
                      style="font-size: 2rem"
                      id="tooltip-target-1"
                    >
                      {{ real_time_data.consume.toFixed(2) }}
                    </div>
                    <div class="mb-25">
                      <label>kWh</label>
                    </div>
                    <b-badge variant="light-danger">
                      {{
                        real_time_data.increase_decrease_consume > 0 ? "+" : ""
                      }}{{ real_time_data.increase_decrease_consume.toFixed(2) }}%
                    </b-badge>
                    <b-tooltip
                      target="tooltip-target-1"
                      triggers="hover"
                      variant="danger"
                    >
                      截至
                      <span>{{ real_time_data.last_datetime }}</span>
                    </b-tooltip>
                  </div>
                </b-alert>
              </b-col>

              <b-col cols="4">
                <b-alert show variant="success" class="mb-0">
                  <div class="alert-body text-center py-2">
                    <b-icon-sunrise-fill class="my-50" font-scale="1.5" />
                    <p class="mb-1">发电量</p>
                    <div
                      class="text-success font-digit text-truncate"
                      style="font-size: 2rem"
                      id="tooltip-target-2"
                    >
                      {{ real_time_data.produce.toFixed(2) }}
                    </div>
                    <div class="mb-25">
                      <label>kWh</label>
                    </div>
                    <b-badge variant="light-success">
                      {{
                        real_time_data.increase_decrease_produce > 0 ? "+" : ""
                      }}{{ real_time_data.increase_decrease_produce.toFixed(2) }}%
                    </b-badge>
                    <b-tooltip
                      target="tooltip-target-2"
                      triggers="hover"
                      variant="success"
                    >
                      截至
                      <span>{{ real_time_data.last_datetime }}</span>
                    </b-tooltip>
                  </div>
                </b-alert>
              </b-col>

              <b-col cols="4">
                <b-alert show variant="warning" class="mb-0">
                  <div class="alert-body text-center py-2">
                    <b-icon-safe-fill class="my-50" font-scale="1.5" />
                    <p class="mb-1">用水量</p>
                    <div
                      class="text-warning font-digit text-truncate"
                      style="font-size: 2rem"
                      id="tooltip-target-3"
                    >
                      {{ real_time_data.water.toFixed(2) }}
                    </div>
                    <div class="mb-25">
                      <label>m<sup>3</sup></label>
                    </div>
                    <b-badge variant="light-warning"
                      >{{ real_time_data.increase_decrease_water.toFixed(2) }}%
                    </b-badge>
                    <b-tooltip
                      target="tooltip-target-3"
                      triggers="hover"
                      variant="warning"
                    >
                      截至
                      <span>{{ real_time_data.last_datetime }}</span>
                    </b-tooltip>
                  </div>
                </b-alert>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>

      <!-- 建筑图片 -->
      <b-col
        cols="6"
        style="pointer-events: none; display: flex; flex-direction: column"
      >
        <b-card
          style="
            height: 493px;
            background-image: url(/images/bg_xjjky2.jpg);
            background-position: center center;
            background-size: cover;
          "
        >
          <b-card-text>
            <h1
              class="font-title text-center mt-1 mb-0 text-dark"
              style="font-size: 2rem; pointer-events: auto"
            >
              新疆建筑科学研究院零能耗建筑示范楼
            </h1>
            <p class="text-center text-dark" style="pointer-events: auto">
              Zero Energy Demo Building at Xinjiang Academy of Building Research
            </p>
          </b-card-text>
        </b-card>

        <b-card>
          <b-row>
            <b-col cols="3" class="d-flex align-items-center">
              <b-avatar class="mr-1" rounded variant="light-primary">
                <b-icon-building font-scale="1.5"></b-icon-building>
              </b-avatar>
              <div>
                <span>建筑面积</span><br />
                <span class="text-primary">1130.00</span>
                <small>&nbsp;m<sup>2</sup></small>
              </div>
            </b-col>

            <b-col cols="3" class="d-flex align-items-center">
              <b-avatar class="mr-1" rounded variant="light-success">
                <b-icon-stack font-scale="1.5"></b-icon-stack>
              </b-avatar>
              <div>
                <span>建筑层数</span><br />
                <span class="text-success">3.00</span>
              </div>
            </b-col>

            <b-col cols="3" class="d-flex align-items-center">
              <b-avatar class="mr-1" rounded variant="light-warning">
                <b-icon-box font-scale="1.5"></b-icon-box>
              </b-avatar>
              <div>
                <span>体形系数</span><br />
                <span class="text-warning">0.32</span>
              </div>
            </b-col>

            <b-col cols="3" class="d-flex align-items-center">
              <b-avatar class="mr-1" rounded variant="light-info">
                <b-icon-sun-fill font-scale="1.5"></b-icon-sun-fill>
              </b-avatar>
              <div>
                <span>光伏容量</span><br />
                <span class="text-info">61.12</span>
                <small>&nbsp;kWp</small>
              </div>
            </b-col>
          </b-row>
        </b-card>
        <b-col style="align-self: stretch"></b-col>
      </b-col>

      <!-- 标识状态 -->
      <b-col cols="3">
        <b-card>
          <b-card-title class="text-truncate">
            标识认证
            <small>Certificate Label</small>
          </b-card-title>
          <b-card-text>
            <b-row>
              <b-col class="text-center" cols="6">
                <b-img
                  :src="
                    require('@/assets/images/buildings/label_nearly_zero.png')
                  "
                  fluid
                  style="height: 8rem"
                />

                <h3
                  class="font-weight-bolder mt-2"
                  style="letter-spacing: 0.5rem"
                >
                  零能耗建筑
                </h3>

                <small
                  >Zero Energy Building<br />
                  （设计阶段）</small
                >
              </b-col>

              <b-col cols="6">
                <p>
                  证书编号<br />
                  <b-badge variant="light-success">N/A </b-badge>
                </p>
                <p>
                  颁证机构<br />
                  <b-badge variant="light-success">N/A </b-badge>
                </p>
                <p>
                  认证日期<br />
                  <b-badge variant="light-success">N/A </b-badge>
                </p>
                <p>
                  项目类型<br />
                  <b-badge variant="light-success">公共建筑 </b-badge>
                </p>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>

        <b-card style="height: 262px">
          <b-card-title class="text-truncate">
            评价指标
            <small>Evaluation Index</small>
          </b-card-title>
          <b-card-text>
            <b-row>
              <b-col
                cols="12"
                class="d-flex align-items-center justify-content-between"
              >
                <div class="d-flex align-items-center">
                  <b-avatar
                    rounded
                    variant="light-success"
                    class="mr-1"
                    size="36"
                  >
                    <b-icon-graph-down scale="1.5"></b-icon-graph-down>
                  </b-avatar>
                  <div>
                    建筑综合节能率<br />
                    <span class="h3 text-success">101.12</span>
                    <span>&nbsp;%</span>
                  </div>
                </div>
                <div class="text-right">
                  <small>优于国标要求</small><br />
                  <b-badge variant="light-success">≥60% </b-badge>
                </div>
              </b-col>

              <b-col
                cols="12"
                class="d-flex align-items-center justify-content-between mt-1"
              >
                <div class="d-flex align-items-center">
                  <b-avatar
                    rounded
                    variant="light-danger"
                    class="mr-1"
                    size="36"
                  >
                    <b-icon-shield-check scale="1.5"></b-icon-shield-check>
                  </b-avatar>
                  <div>
                    建筑本体节能率<br />
                    <span class="h3 text-danger">32.78</span>
                    <span>&nbsp;%</span>
                  </div>
                </div>
                <div class="text-right">
                  <small>优于国标要求</small><br />
                  <b-badge variant="light-danger">≥20% </b-badge>
                </div>
              </b-col>

              <b-col
                cols="12"
                class="d-flex align-items-center justify-content-between mt-1"
              >
                <div class="d-flex align-items-center">
                  <b-avatar
                    rounded
                    variant="light-warning"
                    class="mr-1"
                    size="36"
                  >
                    <b-icon-arrow-clockwise
                      scale="1.5"
                    ></b-icon-arrow-clockwise>
                  </b-avatar>
                  <div>
                    可再生能源利用率<br />
                    <span class="h3 text-warning">145.24</span>
                    <span>&nbsp;%</span>
                  </div>
                </div>
                <div class="text-right">
                  <small>优于国标要求</small><br />
                  <b-badge variant="light-warning">≥100% </b-badge>
                </div>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <!-- 室内环境 -->
      <b-col cols="5" class="pb-0">
        <b-card style="height: 30vh; max-height: 320px" class="mb-1">
          <b-card-title class="text-truncate">
            室内环境
            <small>Indoor Environment</small>
          </b-card-title>

          <b-card-text style="height: calc(100% - 3rem)">
            <b-row class="h-100">
              <b-col cols="4">
                <statistic-card-with-area-chart
                  v-if="!chartLoading"
                  :chart-data="subscribersGained.series"
                  :statistic="
                    kFormatter(subscribersGained.analyticsData.subscribers)
                  "
                  icon="ThermometerIcon"
                  style="height: 100%"
                  statistic-title="温度 (℃)"
                />
                <div style="position: absolute; bottom: 0">
                  <small class="mr-50">国标范围</small>
                  <b-badge variant="light-primary">20℃~26℃ </b-badge>
                </div>
              </b-col>

              <b-col cols="4">
                <statistic-card-with-area-chart
                  v-if="!chartLoading"
                  :chart-data="quarterlySales.series"
                  :statistic="kFormatter(quarterlySales.analyticsData.sales)"
                  color="warning"
                  icon="DropletIcon"
                  style="height: 100%"
                  statistic-title="湿度 (%)"
                />
                <div style="position: absolute; bottom: 0">
                  <small class="mr-50">国标范围</small>
                  <b-badge variant="light-warning">30%~60% </b-badge>
                </div>
              </b-col>

              <b-col cols="4">
                <statistic-card-with-area-chart
                  v-if="!chartLoading"
                  :chart-data="carbonDioxide.series"
                  :statistic="kFormatter(carbonDioxide.analyticsData.co2)"
                  color="success"
                  icon="RadioIcon"
                  style="height: 100%"
                  statistic-title="CO2 (ppm)"
                />
                <div style="position: absolute; bottom: 0">
                  <small class="mr-50">国标范围</small>
                  <b-badge variant="light-success">≤900 ppm </b-badge>
                </div>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>

      <!-- 冷热负荷 -->
      <b-col cols="2">
        <b-card style="height: 30vh; max-height: 320px" class="mb-1">
          <b-card-title class="text-truncate">
            冷热负荷
            <small>Heating &amp; cooling loads</small>
          </b-card-title>

          <div class="d-flex align-items-center justify-content-between">
            <div>
              <span class="bullet bullet-sm bullet-warning mr-1"></span>
              <span>瞬时</span>
            </div>
            <div>
              <span
                class="text-warning"
                style="font-size: 1.5rem"
                id="tooltip-target-4"
                >{{ coldHotLoad.JKYZEB_YH_HOT.toFixed(2) }}</span
              >
              <small>&nbsp;GJ/h</small>
            </div>
          </div>

          <div class="d-flex align-items-center justify-content-between">
            <div>
              <span class="bullet bullet-sm bullet-info mr-1"></span>
              <span>累计</span>
            </div>
            <div>
              <span
                class="text-info"
                style="font-size: 1.5rem"
                id="tooltip-target-5"
                >{{ coldHotLoad.JKYZEB_YH_HOJ.toFixed(2) }}</span
              >
              <small>&nbsp;GJ</small>
            </div>
          </div>

          <hr class="mb-0 offsetMargin" />

          <b-row>
            <b-col cols="6" class="borderRight">
              <label class="text-muted">
                <b-icon-tablet-fill class="mr-50" />
                空气源热泵
              </label>
              <div class="text-center">
                <div>
                  <span
                    class="text-warning"
                    style="font-size: 1.2rem"
                    id="tooltip-target-4"
                    >{{ coldHotLoad.JKYZEB_QYRB_HOT.toFixed(2) }}</span
                  >
                  <small>&nbsp;GJ/h</small>
                </div>
                <span>瞬时</span>

                <div class="mt-1">
                  <span
                    class="text-info"
                    style="font-size: 1.2rem"
                    id="tooltip-target-5"
                    >{{ coldHotLoad.JKYZEB_QYRB_HOJ.toFixed(2) }}</span
                  >
                  <small>&nbsp;GJ</small>
                </div>
                <span>累计</span>
              </div>
            </b-col>

            <b-col cols="6">
              <label class="text-muted">
                <b-icon-file-break-fill class="mr-50" />
                地源热泵
              </label>

              <div class="text-center">
                <div>
                  <span
                    class="text-warning"
                    style="font-size: 1.2rem"
                    id="tooltip-target-4"
                    >{{ coldHotLoad.JKYZEB_DYRB_HOT.toFixed(2) }}</span
                  >
                  <small>&nbsp;GJ/h</small>
                </div>
                <span>瞬时</span>

                <div class="mt-1">
                  <span
                    class="text-info"
                    style="font-size: 1.2rem"
                    id="tooltip-target-5"
                    >{{ coldHotLoad.JKYZEB_DYRB_HOJ.toFixed(2) }}</span
                  >
                  <small>&nbsp;GJ</small>
                </div>
                <span>累计</span>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <!-- 能耗分析 -->
      <b-col cols="5" class="pb-0">
        <b-card style="height: 30vh; max-height: 320px" class="mb-1">
          <b-card-title class="text-truncate">
            能耗分析
            <small>Energy Analysis</small>
          </b-card-title>

          <b-card-text style="height: calc(100% - 3rem)">
            <b-row class="h-100">
              <b-col cols="5">
                <vue-apex-charts
                  v-if="!chartLoading"
                  :options="earningsChart.chartOptions"
                  :series="earningsChart.series"
                  height="100%"
                />
              </b-col>

              <b-col
                class="d-flex flex-column justify-content-between"
                cols="7"
              >
                <vue-apex-charts
                  v-if="!chartLoading"
                  id="revenue-report-chart"
                  :options="revenueReport.chartOptions"
                  :series="revenueReport.series"
                  height="100%"
                  type="line"
                />
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import StatisticCardWithAreaChart from "@/@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import { $themeColors } from "@themeConfig";
import { kFormatter } from "@/@core/utils/filter";
import VueApexCharts from "vue-apexcharts";
import store from "@/store";
import { momentJs } from "@/libs/utils/moment";
import { getHotColdLoad } from "@/api/xj";

export default {
  name: "NearlyZero",
  components: {
    StatisticCardWithAreaChart,
    VueApexCharts,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      realTimeEnergies: [],
      intervalFlag: null,
      today: momentJs().format("YYYY-MM-DD") + "  00:00:00",
      coldHotLoad: {
        JKYZEB_YH_HOT: 0,
        JKYZEB_YH_HOJ: 0,
        JKYZEB_QYRB_HOT: 0,
        JKYZEB_QYRB_HOJ: 0,
        JKYZEB_DYRB_HOT: 0,
        JKYZEB_DYRB_HOJ: 0,
      },
      real_time_data: {
        produce: 0.0,
        consume: 0.0,
        water: 0.0,
        increase_decrease_consume: 0.0,
        increase_decrease_produce: 0.0,
        increase_decrease_water: 0.0,
      },
      chartLoading: true,
      //babylonjs
      _scene: null,
      _engine: null,
      _hl: null,
      isOverlayVisible: false,

      subscribersGained: {
        series: [
          {
            name: "温度",
            data: [],
          },
        ],
        analyticsData: {
          subscribers: 0,
        },
      },
      quarterlySales: {
        series: [
          {
            name: "湿度",
            data: [40, 50, 60, 50, 40, 30, 50],
          },
        ],
        analyticsData: {
          sales: 0,
        },
      },
      energyConsumption: {
        series: [
          {
            name: "二氧化碳",
            data: [80, 60, 32, 46, 28, 14, 25],
          },
        ],
        analyticsData: {
          energy: 35,
        },
      },
      carbonDioxide: {
        series: [
          {
            name: "co2",
            data: [80, 90, 70, 60, 80, 40, 50],
          },
        ],
        analyticsData: {
          co2: 0,
        },
      },
      gaugeOptions: {
        radius: 0.9,
        range: {
          color: "#30BF78",
        },
        indicator: {
          pointer: {
            style: {
              stroke: "#D0D0D0",
            },
          },
          pin: {
            style: {
              stroke: "#D0D0D0",
            },
          },
        },
        statistic: {
          content: {
            style: {
              fontSize: 16,
            },
            formatter: ({ percent }) => `得分: ${(percent * 100).toFixed(0)}`,
          },
        },
        gaugeStyle: {
          lineCap: "round",
        },
      },
      earningsChart: {
        series: [53, 16, 31, 23],
        chartOptions: {
          chart: {
            type: "donut",
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: true,
            position: "bottom",
            labels: {
              colors: "#808695",
            },
          },

          labels: ["照明", "空调", "动力", "特殊"],
          stroke: { width: 0 },

          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 20,
                  },
                  value: {
                    offsetY: -20,
                    color: "#808695",
                    formatter: (e) => {
                      return parseFloat(e).toFixed(2) + " kWh";
                    },
                  },
                  total: {
                    show: true,
                    label: "总计",
                    color: "#808695",
                    formatter: (e) => {
                      return (
                        e.globals.initialSeries
                          .reduce((p, n) => p + n, 0)
                          .toFixed(2) + " kWh"
                      );
                    },
                  },
                },
              },
            },
          },
        },
      },
      revenueReport: {
        series: [
          {
            name: "发电量",
            data: [80, 178, 298, 72, 218, 168, 63, 105, 256, 284, 177, 95],
          },
          {
            name: "耗电量",
            data: [95, 177, 284, 256, 105, 63, 168, 218, 72, 298, 178, 80],
          },
        ],
        chartOptions: {
          chart: {
            stacked: false,
            type: "line",
            toolbar: { show: false },
          },
          grid: {
            yaxis: {
              lines: { show: false },
            },
          },
          stroke: {
            curve: "smooth",
            width: 2,
          },
          xaxis: {
            categories: [
              "00:00",
              "02:00",
              "04:00",
              "06:00",
              "08:00",
              "10:00",
              "12:00",
              "14:00",
              "16:00",
              "18:00",
              "20:00",
              "22:00",
              "24:00",
            ],
            labels: {
              style: {
                colors: "#808695",
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
          title: {
            show: false,
          },
          legend: {
            show: true,
            position: "top",
            labels: {
              colors: "#808695",
            },
          },
          dataLabels: {
            enabled: false,
          },
          colors: [$themeColors.warning, $themeColors.primary],
          plotOptions: {
            bar: {
              columnWidth: "35%",
              borderRadius: 6,
            },
            distributed: true,
          },
          yaxis: {
            labels: {
              style: {
                colors: "#808695",
              },
            },
            title: {
              text: "kWh",
              style: {
                color: "#808695",
              },
            },
          },
        },
      },
    };
  },
  watch: {},
  methods: {
    kFormatter,
    showOverlay() {
      console.log("鼠标移入");
      this.isOverlayVisible = true;
    },
    hideOverlay() {
      console.log("鼠标移出");
      this.isOverlayVisible = false;
    },
    setRealTimeEnergies(index) {
      this.real_time_data.produce = this.realTimeEnergies[index].produce;
      this.real_time_data.water = this.realTimeEnergies[index].water;
      this.real_time_data.consume = this.realTimeEnergies[index].consume;
      this.real_time_data.increase_decrease_water =
        this.realTimeEnergies[index].increase_decrease_water;
      this.real_time_data.increase_decrease_produce =
        this.realTimeEnergies[index].increase_decrease_produce;
      this.real_time_data.increase_decrease_consume =
        this.realTimeEnergies[index].increase_decrease_consume;
      this.real_time_data.last_datetime =
        this.realTimeEnergies[index].last_datetime;
    },
    doGetRealTimeEnergy(type = 1) {
      store.dispatch("xj/fetchRealTimeEnergy", type).then((res) => {
        this.realTimeEnergies = res.data;
        this.setRealTimeEnergies(0);
      });
    },
    doGetPageData() {
      Promise.all([
        store.dispatch("xj/fetchEnvironmentData"),
        store.dispatch("xj/fetchHours24Energy"),
        getHotColdLoad(),
      ]).then((res) => {
        if ("JKYZEB_ENV01_03_T" in res[0].data) {
          this.subscribersGained.series[0].data = res[0].data[
            "JKYZEB_ENV01_03_T"
          ].map((it) => it.EnvDataH / 10);
          this.subscribersGained.analyticsData.subscribers =
            this.subscribersGained.series[0].data[
              this.subscribersGained.series[0].data.length - 1
            ];
        }
        if ("JKYZEB_ENV01_03_RH" in res[0].data) {
          this.quarterlySales.series[0].data = res[0].data[
            "JKYZEB_ENV01_03_RH"
          ].map((it) => it.EnvDataH / 10);
          this.quarterlySales.analyticsData.sales =
            this.quarterlySales.series[0].data[
              this.quarterlySales.series[0].data.length - 1
            ];
        }
        if ("JKYZEB_ENV01_03_CO2" in res[0].data) {
          this.carbonDioxide.series[0].data = res[0].data[
            "JKYZEB_ENV01_03_CO2"
          ].map((it) => it.EnvDataH);
          this.carbonDioxide.analyticsData.co2 =
            this.carbonDioxide.series[0].data[
              this.carbonDioxide.series[0].data.length - 1
            ];
        }
        this.revenueReport.series[0].data = res[1].data.energy_list.generate;
        this.revenueReport.series[1].data = res[1].data.energy_list.cost;
        this.revenueReport.chartOptions.xaxis.categories =
          res[1].data.energy_list.time;
        this.earningsChart.series = res[1].data.pie_data;
        this.chartLoading = false;
        res[2].data.forEach((item) => {
          this.coldHotLoad[item.MeterCode] = item.EnvDataH;
        });
      });
      this.doGetRealTimeEnergy();
    },
  },
  mounted() {
    // 获取数据
    this.doGetPageData();
    this.intervalFlag = setInterval(this.doGetPageData, 60 * 1000);
    // 初始化场景
    // this.initScene("babylonJsCanvas")
  },
  destroyed() {
    clearInterval(this.intervalFlag);
  },
};
</script>

<style lang="scss" scoped>
.adsk-viewing-viewer {
  background: none !important;
}

.homeViewWrapper {
  display: none !important;
}

.offsetMargin {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
</style>
